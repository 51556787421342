@import 'src/styles/colors';

.floating-plus-icon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: #2196f3;
  position: fixed;
  bottom: var(--float-plus-button-bottom);
  right: var(--float-plus-button-right);
  z-index: 10000;
  cursor: pointer;

  & > .new-icon {
    margin: 19px;
  }
}
