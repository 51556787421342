.page-request-reset-password {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  .btn {
    margin: 2em auto;
    display: table;
  }

  .progress {
    height: 20px !important;
    width: 20px !important;
    vertical-align: middle;
    margin-right: 1em;
  }

  .account {
    display: flex;
    flex-direction: row;
    align-items: center;

    .domain {
      padding: 0 20px;
      border-radius: 4px;
      border: rgba(0, 0, 0, 0.23) 1px solid;
      background: rgba(0, 0, 0, 0.03);
      height: 56px;
      display: flex;
      align-items: center;
    }
  }
}
