@mixin standard_content_margin {
  margin-left: 16px;
  margin-right: 16px;
}

:root {
  --topbar-height: 56px;
  @media (min-width: 600px) {
    --topbar-height: 64px;
  }

  //float plus
  --float-plus-button-bottom: 24px;
  --float-plus-button-right: 24px;

  // will be overriden in code
  --max-height: 100%;
}
