@import 'src/styles/colors';

.qr-code-page {
  .placeholder {
    margin: 0;
  }
}

@media print {
  * {
    visibility: hidden;
  }
  .qr-code {
    visibility: initial;
    position: fixed;
    top: 50%;
    * {
      visibility: initial;
    }
  }
}
