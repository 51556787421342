@import 'src/styles/constants';

.init-delete-tenant {
  max-width: 1000px;
  margin-top: 20px;
  @include standard_content_margin;
}

.float_right {
  float: right;
}
