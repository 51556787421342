.page-change-temporary-password {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  .btn {
    margin: 2em auto;
    display: table;
  }

  .progress {
    height: 20px !important;
    width: 20px !important;
    vertical-align: middle;
    margin-right: 1em;
  }
}
