@import 'src/styles/colors';

.button-row {
  padding: 5px;
}

.edit-profile-page {
  max-width: 1260px;
  min-height: calc(100vh - 64px - 47px * 2);
  margin-top: 30px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content {
  background-color: white;
  border: 2px solid #000;
  padding: 10px;
  text-align: center;
  max-height: 100vh;
  overflow-y: auto;

  & label {
    width: 100%;
  }

  & * {
    max-width: 100%;
  }
}

.image_preview {
  margin: 50px;
}

.confirm_button {
  margin: 20px !important;
}

$avatar-dimension: calc(min((100vw / 3) - 32px, 196px));

.avatar {
  display: flex;
  justify-content: center;
  max-height: 196px;

  &__image {
    max-width: $avatar-dimension;
    max-height: $avatar-dimension;
  }

  &__loader {
    width: $avatar-dimension;
    height: $avatar-dimension;
    color: $text-white;
    background-color: $image-background;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.button-row-first {
  margin-top: 20px !important;
}

.modal_avatar {
  div {
    max-width: none !important;
  }
}
