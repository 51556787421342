.page-mfa-setup {


  .qr-code {
    margin: 30px 0;
    text-align: center;

    svg {
      display: block;
      margin: 0 auto 10px;
    }
  }

  .btn {
    margin: 2em auto;
    display: table;
  }
}

$char-w: 1ch;
$gap: 0.5 * $char-w;
$n-char: 6;
$in-w: $n-char * ($char-w + $gap);

.code_input > .MuiInputBase-root > input {
  display: block;
  margin: 1em auto;
  border: none;
  padding: 0;
  width: $in-w;
  background: repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey $char-w,
      transparent 0,
      transparent $char-w + $gap
    )
    0 100% / #{$in-w - $gap} 2px no-repeat;
  font: 5ch droid sans mono, consolas, monospace;
  letter-spacing: $gap;

  &:focus {
    outline: none;
    color: dodgerblue;
  }
}
