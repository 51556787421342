@import 'src/styles/colors';

.page-mfa-verify {
  width: 100%;
  max-width: 500px;
  margin: auto;

  .btn {
    margin: 2em auto;
    display: table;
  }

  .progress {
    height: 20px !important;
    width: 20px !important;
    vertical-align: middle;
    margin-right: 1em;
  }

  article {
    max-width: 408px;
    background-color: $white-default;
    border-radius: 10px;
    margin: auto;
    padding: 24px;
    text-align: center;

    & > .icon-container {
      margin-top: 65px;
    }

    & > .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $primary-color;
      margin: 8px 0 10px;
    }

    & > .subtitle {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $primary-color;
      margin-top: 12px;
    }

    & > .code-problem-link-container {
      margin-top: 8px;

      & > .code-problem-link {
        cursor: pointer;
        color: $secondary-color;
      }
    }
  }

  main {
    max-width: 408px;
    margin: -20px auto auto;
    text-align: center;
    background-color: $white-default;
    border-radius: 10px;
    padding-bottom: 184px;
    padding-top: 100px;

    $char-w: 1ch;
    $char-h: 3ch;
    $gap: 2.5 * $char-w;
    $n-char: 6;
    $in-w: $n-char * ($char-w + $gap);

    div.digit-box {
      border-radius: 4px;
      //border-width: 2px;
      //border-style: solid;
      width: 3.5 * $char-w;
      height: 4 * $char-w;
      font: $char-h droid sans mono, consolas, monospace;
      display: inline-block;
      position: relative;
      top: 4.15 * $char-w;
      margin-right: 0.5 * $char-w;

      &.empty {
        border-color: $border-default;
      }

      &.active {
        border-color: $secondary-color;
      }

      &.filled {
        border-color: $primary-color;
      }
    }

    $char-w: 1ch;
    $gap: 0.5 * $char-w;
    $n-char: 6;
    $in-w: $n-char * ($char-w + $gap);

    .code_input > .MuiInputBase-root > input {
      display: block;
      margin: 1em auto;
      border: none;
      padding: 0;
      width: $in-w;
      background: repeating-linear-gradient(
          90deg,
          dimgrey 0,
          dimgrey $char-w,
          transparent 0,
          transparent $char-w + $gap
        )
        0 100% / #{$in-w - $gap} 2px no-repeat;
      font: 5ch droid sans mono, consolas, monospace;
      letter-spacing: $gap;

      &:focus {
        outline: none;
        color: dodgerblue;
      }
    }

    .MuiInput-underline {
      &::after,
      &::before,
      &:hover::before {
        border: 0;
      }
    }
  }
}
