.text-label {
  width: 100%;
  padding: 0.5em 0 0.7em 0;
  border-bottom: solid 1px #ccc;

  &__content {
    color: #111;
    font-size: 17px;
    margin: 0;
  }

  &__label {
    margin: 0;
    padding-bottom: 0.3em;
    text-transform: uppercase;
    color: #999;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
}
