@import 'styles/constants';

body,
html {
  height: var(--max-height);
  padding: 0;
  margin: 0;

  #root {
    height: 100%;
  }
}

body {
  max-height: var(--max-height);
}

img {
  max-width: 100%;
}
